$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    // create instance of the plugin, but hold on till needed
    var acc = $('.accHandler').accordion({onhold: true, openFirst: true});
    $win = $(window);

    // start the accordion when window is smaller than 902px
    $win.resize(function() {
        if (breakpoint['medium']) acc.destroy();
        else acc.init();
    });
  
    // trigger resize to check if accordion is needed
    $win.trigger('resize');

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * REPLACE a[href^="tel"] BY SPAN ELEMENT IF NOT MOBILE DEVICE
     */
    if(!('ontouchstart' in window || navigator.maxTouchPoints)) {
        phonelink();
    }

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if (breakpoint['medium']) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');
	/* fix navi*/
	 var elementTopPosition= $('.page-navi').offset().top -134; 
	 if($('body').hasClass('index')){
		 $(window).scroll(function(){
		  if($(window).scrollTop() > elementTopPosition)
		   $('body').addClass('stuck');
		  else
		   $('body').removeClass('stuck');
		});
	 }
	 else{
		 $(window).scroll(function(){
		  if($(window).scrollTop() > 2)
		   $('body').addClass('stuck');
		  else
		   $('body').removeClass('stuck');
		});
	}
	/* slider call */
	$('.slider').slick({
		 fade: true,
		 autoplay: true
	});
	/* show inputs for firma*/
	$(".contact-form input[type='radio']").on('change', function() {
		var radioChecked = ($(this).val());
		if(radioChecked == "Firma"){
			$(this).parents('form').find('.hidden-text').css('display', 'block');
		}
		else{
			$(this).parents('form').find('.hidden-text').css('display', 'none');
		}
	});
	$('#Datenschutz-error').next('label[for="Datenschutz"]').addClass('error');
	/* datepicker call*/
	$("#datepicker").datepicker({
		yearRange: "-90:+0",
		dateFormat: "mm.dd.yy",
		changeMonth: true,
        changeYear: true,
	});

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/